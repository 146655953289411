"use client";

import {NEXT_PUBLIC_CLOUDFLARE_LOADER_URL} from "./src/publicEnv";

const cloudflareLoaderUrl = NEXT_PUBLIC_CLOUDFLARE_LOADER_URL;

// Docs: https://developers.cloudflare.com/images/url-format
export default function cloudflareLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  const params = [`width=${width}`, `quality=${quality || 75}`, "format=auto"];
  return `${cloudflareLoaderUrl}/cdn-cgi/image/${params.join(",")}/${src}`;
}
