/*
 Typescript wrappers over public environment variables.
 All public variables should be prefixed with `NEXT_PUBLIC_`.
 All variables should be defined in Vercel.
 */

export const NEXT_PUBLIC_TALKDESK_FLOW_ID = process.env.NEXT_PUBLIC_TALKDESK_FLOW_ID;
export const NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY;
export const NEXT_PUBLIC_GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const NEXT_PUBLIC_IMAGES_URL = process.env.NEXT_PUBLIC_IMAGES_URL;
export const NEXT_PUBLIC_CLOUDFLARE_LOADER_URL = process.env.NEXT_PUBLIC_CLOUDFLARE_LOADER_URL;
