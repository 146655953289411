import {useEffect, useState} from "react";

import {addListenerSafe, removeListenerSafe} from "./utils/mediaQueries";
import {noOp} from "./utils/noOp";

type Breakpoint = "xs" | "sm" | "md" | "lg" | "lgxl" | "xl";

const BreakpointMap = {
  xs: 480,
  sm: 767,
  md: 992,
  lg: 1024,
  lgxl: 1200,
  xl: 1440,
};

type MQOptions = {
  min?: boolean;
  isHeight?: boolean;
};

/**
 * xs: 480
 * sm: 767
 * md: 992
 * lg: 1024
 * lgxl: 1200
 * xl: 1440
 * @returns Boolean, whether or not the screen size matches the query
 * @deprecated Use useTwMediaQuery() instead
 */
const useMediaQuery = (bp: Breakpoint, options?: MQOptions): boolean => {
  const min = options?.min || false;
  const height = options?.isHeight || false;

  const dimension = height ? "height" : "width";
  const queryString = min
    ? `(min-${dimension}: ${BreakpointMap[bp]}px)`
    : `(max-${dimension}: ${BreakpointMap[bp]}px)`;

  const [matches, setMatches] = useState(false);
  useEffect(() => {
    if (typeof matchMedia !== "undefined") {
      const mediaQueryList = matchMedia(queryString);
      setMatches(mediaQueryList.matches);
      const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
      addListenerSafe(mediaQueryList, listener);
      return () => {
        removeListenerSafe(mediaQueryList, listener);
      };
    }
    return noOp;
  }, [queryString]);

  return matches;
};

export default useMediaQuery;
